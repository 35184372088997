<template>
  <header class="sticky-Nav" id="menuBar">
    <div class="container">
      <b-navbar id="menuContainer" class="py-lg-4 px-lg-5 mt-lg-5 w-sm-100 rounded " toggleable="lg" type="light" variant="light" sticky>

        <b-navbar-brand class="desktop-logo" data-toggle="nav-collapse" data-target="nav-collapse" to="/" >
            <img class="logo-styling" src="./../assets/images/BN-Resized.png"/>
        </b-navbar-brand>
    
        <b-navbar-brand data-toggle="nav-collapse" data-target="nav-collapse" to="/" >
          <b-navbar-toggle target="nav-collapse" to="/" >
            <img class="logo-styling" alt="BizzyNate Creative, Inc. logo" src="./../assets/images/BN-Resized.png"/>
          </b-navbar-toggle>
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse" class="sm:hidden" title="Mobile Only: Use this to expand or collapse the navigation"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>

          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
                  <li class="custom-nav-link responsive-align-right">
                    <router-link to="/about"  title="Learn More About Our Company">About</router-link>
                  </li>
                  <li class="custom-nav-link responsive-align-right">
                    <router-link to="/projects" right title="Learn More About Our Projects">Projects</router-link>
                  </li>
                  <li class="responsive-align-right">
                    <b-button variant="primary" class="nav-btn btn-block" to="/contact" right title="Get In Touch With Us">
                      Contact Us
                    </b-button>
                  </li>

          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
  </div>
  </header>
</template>

<script>

  export default {
    data() {
      return {
      }
    },
    methods: {
    },
    mounted() {

const menuBar = document.getElementById('menuBar');
const menuContainer = document.getElementById('menuContainer');

  window.addEventListener("scroll", () => {
    if (window.scrollY < 20) {
      menuBar.classList.remove('menuFull');
      menuBar.classList.add('menuFade');
      menuBar.classList.remove('shadow-sm');
      menuContainer.classList.add('shadow-sm');
    } else {
      menuBar.classList.add('menuFull');
      menuBar.classList.remove('menuFade');
      menuBar.classList.add('shadow-sm');
      menuContainer.classList.remove('shadow-sm');
    }
    });


    }
  }
</script>