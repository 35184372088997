<template>
  <div id="app">
    <ul class="sr-only">
      <li><a href="#menuContainer">Skip to Navigation</a></li>
      <li><a href="#content">Skip to Content</a></li>
    </ul>
    <NavBar/>

    <!-- Body Content -->
      <main id="content" class="hide-X">
          <router-view/>
      </main>
      
    <Footer/>
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    NavBar,
    Footer
  }
}
</script>

<style>
@import url('https://use.typekit.net/srx8bln.css');

@font-face {
  font-family: "Aller_Rg";
  src: local("Aller_Rg"),
   url(./assets/fonts/Aller_Rg.ttf) format("truetype");
}

@font-face {
  font-family: "Aller_Bd";
  src: local("Aller_Bd"),
   url(./assets/fonts/Aller_Bd.ttf) format("truetype");
}

</style>
