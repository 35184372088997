<template>
    <article v-if="project.type != 86" class="col-xl-4 col-lg-6 col-md-12 text-left mt-5 px-xl-5" :key="project.id">
        <div class="project-description-wrapper">
            <div class="project-image-wrapper shadow rounded">
                <img aria-hidden="true" v-if="project.image_thumbnail" @click="$emit('clicked', project)"
                    :src="require(`../assets/${project.image_url}`)" :alt="`${project.name} Image`"
                    style="max-width: inherit; width:100%" />
            </div>
            <h3 class="custom-small-header aller-bd pt-4 pb-2"><span class="sr-only">Name: </span>{{ project.name }}</h3>
            <p class="project-small-header pt-1 pb-2"><span class="sr-only">Type: </span>{{ project.headline }}</p>
            <p class="custom-body proxima">
                {{ project.description }}
            </p>
        </div>
    </article>
</template>

<script>
export default {
    props: {
        project: {
            type: Object,
            required: true
        }
    },
    emits: [
        'clicked'
    ]
}
</script>